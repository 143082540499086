import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import Title from '../../components/Title';
import Clause from './Clause';
import styles from './styles.module.sass';
import clauses from '../../data/cookie-clauses';

export default ({location}) => (
  <Layout
    url='_url:cookie-policy'>
    <SEO title="Cookie policy" location={location}/>
    <div className={styles.container}>
      <Title variant='h1' align='center'>
        About cookies
      </Title>
      {clauses.map((c, key) => (
        <Clause key={key}
          title={c.title}
          descriptions={c.descriptions}/>
        )
      )}
    </div>
  </Layout>
);