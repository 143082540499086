import React from 'react';
import Typography from '../../components/Typography';
import styles from './styles.module.sass';

export default ({ title = '', descriptions }) => (
  <>
    {title 
    ? <Typography variant='h4' 
        align='left'
        color='dark'
        className={styles.clauseTitleContainer}>
        {title}
      </Typography>
    : null}
    {descriptions.map(description => (
        <Typography variant='subtitle2' 
          align='left'
          color='main'
          weight='light' 
          className={styles.clauseDescriptionContainer}>
          {description}
        </Typography>
      )
    )}
  </>
);